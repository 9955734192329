import { SamplingContext } from '@sentry/types';

export const getTracesSampler = (samplingContext: SamplingContext) => {
  if (samplingContext.transactionContext.name == 'GET /api/auth/me') {
    return 0.0; // never send transactions with name GET /api/auth/me
  }

  return 0.4; // sampling for all other transactions
};

export const ignoreErrors = [
  "Can't find variable: VK",
  'VK is not defined',
  "Cannot read properties of undefined (reading 'win')",
  "undefined is not an object (evaluating 'this.contrAgent.win')",
  'this.contrAgent is undefined',
  "Failed to execute 'evaluate' on 'Document': The string '' is not a valid XPath expression.",
  'Invariant: attempted to hard navigate to the same URL / https://azalianow.ru/',
];
