var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"N0mkwLkFjHBCFy14sqxJv"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/import {
import { Replay, init } from '@sentry/nextjs';

import env from '@/helpers/server/env';

import { IS_SENTRY_ENABLED } from '@/constants/common.constants';

import { getTracesSampler, ignoreErrors } from '@/config/sentry';

init({
  enabled: IS_SENTRY_ENABLED,
  environment: env.NEXT_PUBLIC_SENTRY_ENV,
  dsn: env.NEXT_PUBLIC_SENTRY_DSN,

  ignoreErrors: ignoreErrors,

  tracesSampler: (samplingContext) => getTracesSampler(samplingContext),

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new Replay({
      // Additional Replay configuration goes in here, for example:
      maskAllInputs: true,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
});
