import env from '@/helpers/server/env';

import { PaymentOption } from '@/pages/Checkout/basket-form/forms/payment-form/payment-сonfiguration.config';

import { IOrderItem } from '@/types/new/orders';
import { IProductCheckoutNew, IProductNew, IProductRubric } from '@/types/new/products';

type YaMetricGoalNames =
  | 'downloadApplicationModal'
  | 'nazhali-na-knopku-zakazat-na-kartochku-tovara'
  | 'v-korzinu-modalka'
  | 'kupit-s-menedzherom'
  | 'knopka-chat-s-podderzhkoj'
  | 'knopka-svyazatsya-v-whatsapp'
  | 'knopka-svyazatsya-v-telegram'
  | 'knopka-v-korzinu-na-stranice-tovara'
  | 'knopka-korzina:-oformit-zakaz'
  | 'knopka-korzina:-dalee-poluchatel'
  | 'knopka-korzina:-vybor-dostavki'
  | 'knopka-korzina:-data-i-vremya-(samovyvoz)'
  | 'knopka-korzina:-dalee-oplata'
  | 'knopka-korzina:-dolyami'
  | 'knopka-korzina:-oplatit'
  | 'knopka-katalog'
  | 'poisk:-nazhatie-na-knopku-najti-libo-enter'
  | 'knopka-profil'
  | 'knopka-oformit-zakaz:-ne-v-kabinete'
  | 'registraciya:-nomer-vveden-uspeshno'
  | 'knopka-vojti-registraciya'
  | 'klik-po-nomeru-telefona-v-shapke'
  | 'klik-po-nomeru-telefona-v-podvale'
  | 'klik-po-email-v-podvale'
  | 'klik-po-telegram-v-podvale'
  | 'klik-podderzhka-v-shapke'
  | 'klik-podderzhka:-chat-na-sajte'
  | 'klik-podderzhka:-napisat-v-telegram'
  | 'klik-podderzhka:-napisat-v-whatsapp'
  | 'klik-korporativnym-klientam-v-shapke'
  | 'klik-po-telefonu:-korporativnym-klientam'
  | 'klik-napisat-v-telegram-korporativnym-klientam'
  | 'klik-po-napisat-v-whatsapp-korporativnym-klientam'
  | 'knopka-otpravit-korporativnym-klientam-soberem-buket-po-foto'
  | 'knopka-otpravit-soberem-buket-po-foto'
  | 'klik-kontakty-v-shapke'
  | 'new-customer'
  | 'klik-kontakty-v-podvale'
  | 'order-sum-is-to-low';

const ECommerceEvents = {
  viewPromotion: 'view_promotion',
  selectPromotion: 'select_promotion',
  viewItemList: 'view_item_list',
  selectItem: 'select_item',
  viewItem: 'view_item',
  addToCart: 'add_to_cart',
  addToWishlist: 'add_to_wishlist',
  removeFromCart: 'remove_from_cart',
  viewCart: 'view_cart',
  beginCheckout: 'begin_checkout',
  addShippingInfo: 'add_shipping_info',
  addPaymentInfo: 'add_payment_info',
  purchase: 'purchase',
};

const AZALIA_NOW = 'AzaliaNow';

class AnalyticMetric {
  private siteCode = env.NEXT_PUBLIC_YANDEX_METRIKA_SITE_KEY;

  hitGoal(goalName: YaMetricGoalNames, params?: Record<string, any>) {
    if (typeof window !== 'undefined' && typeof window.ym !== 'undefined') {
      window.ym(this.siteCode, 'reachGoal', goalName, { ...params });
    } else {
      console.log(`hitGoal ${goalName} doesn't not initialize`);
    }
  }

  hitPage(url: string) {
    if (typeof window !== 'undefined' && typeof window.ym !== 'undefined') {
      window.ym(this.siteCode, 'hit', url, {
        params: {
          referer: window.location.href,
        },
      });
    } else {
      console.log("hitPage doesn't initialize");
    }
  }

  viewingTheProductList(
    productList: IProductNew[],
    firstPosition: number,
    listName: string,
    category = ''
  ) {
    let positionCounter = firstPosition;
    if (typeof window !== 'undefined') {
      const products = productList.map((product) => {
        const p = {
          id: product.id,
          name: product.name,
          price: product.price,
          brand: AZALIA_NOW,
          list: listName,
          position: positionCounter,
        };
        positionCounter += 1;
        return p;
      });

      positionCounter = firstPosition;
      const items = productList.map((product) => ({
        item_id: product.id,
        item_name: product.name,
        price: product.price,
        item_brand: AZALIA_NOW,
        item_list_name: listName,
        item_category: category,
        index: ++positionCounter,
        quantity: 1,
      }));

      window.gtag &&
        window.gtag('event', ECommerceEvents.viewItemList, {
          item_list_name: listName,
          items,
        });

      window.yaDataLayer?.push({
        currencyCode: 'RUB',
        impressions: products,
      });
    }
  }

  addShippingInfo(
    products: IProductCheckoutNew[],
    shippingTariff: 'default' | 'express' | 'just-in-time'
  ) {
    const shippingProducts = products.map(({ product, amount }) => ({
      item_name: product.name,
      item_id: product.id,
      price: product.price,
      item_brand: AZALIA_NOW,
      item_category: '',
      index: 1,
      quantity: amount,
    }));
    if (typeof window !== 'undefined') {
      const totalPrice = shippingProducts.reduce(
        (prevPrice, { quantity, price }) => prevPrice + quantity * price,
        0
      );
      window.gtag &&
        window.gtag('event', ECommerceEvents.addShippingInfo, {
          currency: 'RUB',
          shipping_tier: shippingTariff,
          value: totalPrice,
          items: shippingProducts,
        });
    }
  }

  addPaymentInfo(products: IProductCheckoutNew[], payment: PaymentOption, promocode: string) {
    const items = products.map(({ product, amount }) => ({
      item_name: product.name,
      item_id: product.id,
      price: product.price,
      item_brand: AZALIA_NOW,
      item_category: '',
      index: 1,
      quantity: amount,
    }));
    if (typeof window !== 'undefined') {
      const totalPrice = items.reduce(
        (prevPrice, { quantity, price }) => prevPrice + quantity * price,
        0
      );
      window.gtag &&
        window.gtag('event', ECommerceEvents.addPaymentInfo, {
          currency: 'RUB',
          value: totalPrice,
          payment_type: payment.value,
          coupon: promocode,
          items,
        });
    }
  }

  //TODO: Можно объединить с pushPaymentData
  purchase(
    products: IOrderItem[],
    paymentId: string,
    deliveryPrice: string | undefined,
    price: number,
    promocode: string | undefined
  ) {
    const items = products.map(({ product, quantity }) => ({
      item_name: product?.name,
      item_id: product?.id,
      price: product?.price,
      item_brand: AZALIA_NOW,
      item_category: '',
      index: 1,
      quantity,
    }));
    if (typeof window !== 'undefined') {
      window.gtag &&
        window.gtag('event', ECommerceEvents.purchase, {
          transaction_id: paymentId,
          shipping: deliveryPrice,
          currency: 'RUB',
          coupon: promocode,
          price,
          items,
        });
    }
  }

  viewEmptyCart() {
    if (typeof window !== 'undefined') {
      window.gtag &&
        window.gtag('event', ECommerceEvents.viewCart, {
          currency: 'RUB',
          value: 0,
          items: [],
        });
    }
  }

  viewCart(products: IProductCheckoutNew[]) {
    const items = products.map(({ product, amount }) => ({
      item_name: product.name,
      item_id: product.id,
      price: product.price,
      item_brand: AZALIA_NOW,
      item_category: '',
      index: 1,
      quantity: amount,
    }));
    const totalPrice = items.reduce(
      (prevPrice, { quantity, price }) => prevPrice + quantity * price,
      0
    );
    if (typeof window !== 'undefined') {
      window.gtag &&
        window.gtag('event', ECommerceEvents.viewCart, {
          currency: 'RUB',
          value: totalPrice,
          items,
        });
    }
  }

  addToWishList(product: IProductNew) {
    if (typeof window !== 'undefined') {
      window.gtag &&
        window.gtag('event', ECommerceEvents.addToWishlist, {
          currency: 'RUB',
          value: product.price,
          items: [
            {
              item_name: product.name,
              item_id: product.id,
              price: product.price,
              item_brand: AZALIA_NOW,
              item_category: '',
              item_list_name: '',
              index: 1,
              quantity: 1,
            },
          ],
        });
    }
  }

  selectPromotion(promotion: {
    id: number | string;
    name: string;
    creative_name: string | undefined;
    position: number;
  }) {
    const items = {
      item_id: promotion.id,
      item_name: promotion.name,
      promotion_id: promotion.id,
      promotion_name: promotion.name,
      creative_name: promotion.creative_name,
      creative_slot: `${promotion.name} - ${promotion.position}`,
    };
    if (typeof window !== 'undefined') {
      window.gtag &&
        window.gtag('event', ECommerceEvents.selectPromotion, {
          items,
        });
    }
  }

  viewingTheProduct(
    product: IProductNew,
    rubrics?: IProductRubric[] | null,
    list?: string,
    position?: number
  ) {
    if (typeof window !== 'undefined') {
      window.gtag &&
        window.gtag('event', ECommerceEvents.viewItem, {
          value: product.price,
          currency: 'RUB',
          items: [
            {
              item_name: product.name,
              item_id: product.id,
              price: product.price,
              item_brand: AZALIA_NOW,
              item_category: '',
              item_list_name: rubrics?.map((rubric) => rubric.name).join(', '),
              index: 1,
              quantity: 1,
            },
          ],
        });

      window.yaDataLayer?.push({
        ecommerce: {
          currencyCode: 'RUB',
          detail: {
            products: [
              {
                id: product.id,
                name: product.name,
                price: product.price,
                brand: AZALIA_NOW,
                ...(list ? { list } : {}),
                ...(position ? { position } : {}),
              },
            ],
          },
        },
      });
    }
  }

  productClick(product: IProductNew, list: string, position: number) {
    if (typeof window !== 'undefined') {
      window.gtag &&
        window.gtag('event', ECommerceEvents.selectItem, {
          item_list_name: list,
          items: [
            {
              item_name: product.name,
              item_id: product.id,
              price: product.price,
              item_brand: AZALIA_NOW,
              item_category: '',
              item_list_name: list,
              index: position,
              quantity: 1,
            },
          ],
        });

      window.yaDataLayer?.push({
        ecommerce: {
          currencyCode: 'RUB',
          click: {
            products: [
              {
                id: product.id,
                name: product.name,
                price: product.price,
                brand: AZALIA_NOW,
                list,
                position,
              },
            ],
          },
        },
      });
    }
  }

  productAddToCartClick(product: IProductNew, list?: string, position?: number) {
    if (typeof window !== 'undefined') {
      window.gtag &&
        window.gtag('event', ECommerceEvents.addToCart, {
          currency: 'RUB',
          value: product.price,
          items: [
            {
              item_name: product.name,
              item_id: product.id,
              price: product.price,
              item_brand: AZALIA_NOW,
              item_category: '',
              item_list_name: list ?? '',
              index: position ?? 1,
              quantity: 1,
            },
          ],
        });

      window.yaDataLayer?.push({
        ecommerce: {
          currencyCode: 'RUB',
          add: {
            products: [
              {
                id: product.id,
                name: product.name,
                price: product.price,
                brand: AZALIA_NOW,
                quantity: 1,
                ...(list ? { list } : {}),
                ...(position ? { position } : {}),
              },
            ],
          },
        },
      });
    }
  }

  productRemoveFromCartClick({
    id,
    name,
    price,
    brand,
  }: {
    id: number;
    name: string;
    price: number;
    brand?: string;
  }) {
    if (typeof window !== 'undefined') {
      window.gtag &&
        window.gtag('event', ECommerceEvents.removeFromCart, {
          value: price,
          currency: 'RUB',
          items: [
            {
              item_id: id,
              item_name: name,
              item_brand: brand ?? AZALIA_NOW,
              price,
              index: 1,
              quantity: 1,
            },
          ],
        });

      window.yaDataLayer?.push({
        ecommerce: {
          currencyCode: 'RUB',
          remove: {
            products: [
              {
                id,
                name,
                quantity: 1,
              },
            ],
          },
        },
      });
    }
  }

  beginCheckout(
    products: {
      item_id: number | string;
      item_name: string;
      item_brand: string;
      price: number;
      quantity: number;
    }[]
  ) {
    if (typeof window !== 'undefined') {
      const totalPrice = products.reduce(
        (prevPrice, { quantity, price }) => prevPrice + quantity * price,
        0
      );
      window.gtag &&
        window.gtag('event', ECommerceEvents.beginCheckout, {
          currency: 'RUB',
          value: totalPrice,
          items: products,
        });
    }
  }

  pushPaymentData({
    order,
    products,
  }: {
    order: { uid: string; promocodeId?: number };
    products: {
      id: number | string;
      name: string;
      price: number | string;
      quantity: number;
    }[];
  }) {
    if (typeof window !== 'undefined') {
      window.yaDataLayer?.push({
        ecommerce: {
          currencyCode: 'RUB',
          purchase: {
            actionField: {
              id: order.uid,
              ...(order.promocodeId ? { coupon: order.promocodeId } : {}),
              goal_id: 3089774221,
            },
            products,
          },
        },
      });
    }
  }

  viewingPromotions(
    promotions: {
      id: number | string;
      name: string;
      creative_name?: string;
      position: number;
    }[]
  ) {
    const items = promotions.map((promotion) => ({
      item_id: promotion.id,
      item_name: promotion.name,
      promotion_id: promotion.id,
      promotion_name: promotion.name,
      creative_name: promotion.name,
      creative_slot: `${promotion.name} - ${promotion.position}`,
      location_id: promotion.position,
    }));
    if (typeof window !== 'undefined') {
      window.gtag &&
        window.gtag('event', ECommerceEvents.viewPromotion, {
          items,
        });

      window.yaDataLayer?.push({
        ecommerce: {
          promoView: {
            promotions,
          },
        },
      });
    }
  }
}

export const analyticMetric = new AnalyticMetric();
