export const markdownToPlainText = (markdown: string) => {
  // Replace headers
  markdown = markdown.replace(/(^#{1,6})\s*(.*)/gm, '$2\n');

  // Replace lists
  markdown = markdown.replace(/^\s*([-*+])\s*/gm, '- ');

  // Replace bold and italic
  markdown = markdown.replace(/(\*|_){1,2}(.*?)\1{1,2}/g, '$2');

  // Remove links
  markdown = markdown.replace(/\[(.*?)\]\((.*?)\)/g, '$1');

  // Remove dividers
  markdown = markdown.replace(/^\s*-{3}|- --\s*$/gm, '');

  // Remove code
  markdown = markdown.replace(/`{1,2}(.*?)`{1,2}/g, '$1');

  return markdown;
};
