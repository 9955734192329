const env = {
  REVALIDATE_TOKEN: process.env.REVALIDATE_TOKEN ?? '',
  PINO_LOG_LEVEL: process.env.PINO_LOG_LEVEL ?? 'debug',
  SELECTION_BUNDLE_ID: process.env.SELECTION_BUNDLE_ID ?? '1',
  TILED_BUNDLE_ID: process.env.TILED_BUNDLE_ID ?? '17',
  RUBRIC_BUNDLE_IDS: process.env.RUBRIC_BUNDLE_IDS?.split(',') ?? [],
  YANDEX_SPLIT_PAGE_RUBRIC_BUNDLE_IDS:
    process.env.YANDEX_SPLIT_PAGE_RUBRIC_BUNDLE_IDS?.split(',') ?? [],
  NEXT_PUBLIC_AZ_BACKEND_URL: process.env.NEXT_PUBLIC_AZ_BACKEND_URL ?? '',
  NEXT_PUBLIC_AZ_BACKEND_URL_V2: process.env.NEXT_PUBLIC_AZ_BACKEND_URL_V2 ?? '',
  NEXT_PUBLIC_UPLOAD_SERVICE_URL: process.env.NEXT_PUBLIC_UPLOAD_SERVICE_URL ?? '',
  NEXT_PUBLIC_APP_STORE_LINK: process.env.NEXT_PUBLIC_APP_STORE_LINK ?? '',
  NEXT_PUBLIC_GOOGLE_PLAY_LINK: process.env.NEXT_PUBLIC_GOOGLE_PLAY_LINK ?? '',
  NEXT_PUBLIC_YANDEX_CAPTCHA_CLIENT_KEY: process.env.NEXT_PUBLIC_YANDEX_CAPTCHA_CLIENT_KEY ?? '',
  NEXT_PUBLIC_ROBOTS_DISABLED: process.env.NEXT_PUBLIC_ROBOTS_DISABLED ?? false,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN ?? '',
  NEXT_PUBLIC_SENTRY_ENV: process.env.NEXT_PUBLIC_SENTRY_ENV ?? '',
  NEXT_PUBLIC_YANDEX_METRIKA_SITE_KEY: process.env.NEXT_PUBLIC_YANDEX_METRIKA_SITE_KEY ?? '',
  NEXT_PUBLIC_GOOGLE_UNIVERSAL_KEY: process.env.NEXT_PUBLIC_GOOGLE_UNIVERSAL_KEY ?? '',
  NEXT_PUBLIC_GOOGLE_GTAG_KEY: process.env.NEXT_PUBLIC_GOOGLE_GTAG_KEY ?? '',
};

export default env;
